const OTP_SUBJECTS = Object.freeze({
  // ===========================================================================
  // Private
  // ===========================================================================
  CONFIG_SETUP: "fb55",
  // ===========================================================================
  // Public
  // ===========================================================================
  // 2FA Configuration =========================================================
  CONFIG_UPDATE: "f5b8",
  CONFIG_STATUS: "916b",
  CONFIG_DELETE: "85c4",

  // Authentication ============================================================
  SIGN_IN: "42df",
  RESET_PASSWORD: "d165",

  // Payments ==================================================================
  CONFIRM_PAYMENT: "d789",

  fb55: "fb55",
  f5b8: "f5b8",
  "916b": "916b",
  "85c4": "85c4",
  "42df": "42df",
  d165: "d165",
  d789: "d789",
});

async function sendOTP({ userId, subject, provider }, $api) {
  const { data: candidate } = await $api.post("auth/2fa/candidate/", {
    body: {
      userId,
      subject,
    },
  });

  return {
    ...candidate?.value,
    provider,
  };
}

export const forgotPassword = async ($api, $notification, { email }) => {
  try {
    const { data } = await $api.post("auth/forgot-pass", {
      body: { email },
    });

    if (!data.value.error) {
      $notification.ADD({
        type: "success",
        title: "If the email is in our system then you will receive the reset instructions shortly",
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const signUp = async ($api, $notification, { email, birthDate, gender, userName, password, country }) => {
  try {
    const { data, error } = await $api.post("auth/sign-up/", {
      body: {
        email,
        birthDate: new Date(birthDate).getTime(),
        gender,
        userName,
        password,
        country,
      },
      server: true,
    });

    if (!error.value && !data?.value?.error) {
      $notification.ADD({
        type: "success",
        title: "Congratulations, your account has been successfully created",
        message: "Please check your email",
      });

      navigateTo("/");
    }
  } catch (error) {
    console.error(error);
  }
};

export const signIn = async ($api, $userStore, { emailOrName, password, remember, OTP = "", reqId = "" }) => {
  try {
    const { data } = await $api.post(`auth/sign-in`, {
      method: "POST",
      body: {
        emailOrName,
        password,
        OTP,
        reqId,
        remember,
      },
    });

    return data.value;
  } catch (error) {
    console.error(error);
  }
};

export const signOut = async ($api, $userStore) => {
  try {
    $api.post(`auth/sign-out`, {
      method: "POST",
    });

    $userStore.LOGOUT();
  } catch (error) {
    console.error(error);
  }
};

export const setup2fa = async ($api, $userStore, { provider = "EMAIL" }) => {
  try {
    const { data } = await $api.post("auth/2fa/setup", {
      body: {
        provider,
      },
    });

    return data;
  } catch (error) {
    return { error };
  }
};

export const check2fa = async ($api, $userStore, { emailOrName = "", fbId = "" }) => {
  try {
    const { data } = await $api.get(`auth/2fa/check?email=${emailOrName}&fbId=${fbId}`);

    if (!data.value?.has2FA) {
      return null;
    }

    return sendOTP(
      {
        userId: data.value._id,
        subject: OTP_SUBJECTS.SIGN_IN,
        provider: data.value.provider,
      },
      $api
    );
  } catch (error) {
    return { error };
  }
};

export const resendOTP = async ($api, $userStore, { emailOrName = "", fbId = "", reqId }) => {
  try {
    const { data } = await $api.get(`auth/2fa/check?email=${emailOrName}&fbId=${fbId}`);

    const { data: candidate } = await $api.patch(`auth/2fa/candidate/${reqId}/`, {
      body: {
        userId: data?.value._id,
      },
    });

    return candidate?.value;
  } catch (error) {
    return { error };
  }
};

export const confirm2fa = async ($api, $userStore, { provider, OTP, reqId }) => {
  try {
    const { data, error } = await $api.post("auth/2fa/", {
      body: {
        provider,
        OTP,
        reqId,
      },
    });

    const errorDetails = error.value?.data?.data?.details;

    if (errorDetails?.newReqId) {
      return { reqId: errorDetails?.newReqId };
    }

    if (errorDetails?.errorCode) {
      return { errorCode: errorDetails?.errorCode };
    }

    return data?.value;
  } catch (error) {
    return { error };
  }
};

export const disable2fa = async ($api, $userStore, { OTP, reqId }) => {
  try {
    const { error } = await $api.post("auth/2fa/disable/", {
      body: {
        OTP,
        reqId,
      },
    });

    return error.value ? false : true;
  } catch (error) {
    return { error };
  }
};

export const request2fa = async ($api) => {
  try {
    const { data } = await $api.post("auth/2fa/request/", {
      body: {
        subject: OTP_SUBJECTS.CONFIG_DELETE,
      },
    });

    return data?.value;
  } catch (error) {
    return { error };
  }
};
